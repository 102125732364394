.form-wrapper {
  align-items: flex-end;
  background-color: var(--color-ghost-white);
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0;
  max-width: 42.75rem;
  padding: 1.5rem;
}

.form-wrapper > div:first-child {
  flex: 1;
}

.th {
  padding: 0.6875rem 0.75rem;
  text-align: left;
  vertical-align: bottom;
  white-space: nowrap;
}

.th[colspan="2"] {
  text-align: center;
}

.upload-row-errors {
  border-collapse: collapse;
  width: 100%;
}

.upload-row-errors__th,
.upload-row-errors__td {
  padding: 0.5rem;
  text-align: left;
}

.upload-row-errors__th:first-of-type {
  width: 0; /* hack to make it shrink wrap */
}

.upload-row-errors__th:last-of-type {
  padding-left: 1rem;
}

.upload-row-errors__td {
  border-top: 1px solid var(--color-pattens-blue);
  color: var(--color-scarpa-flow);
}

.upload-row-errors__td:first-of-type {
  text-align: center;
}

.upload-row-errors__td:last-of-type {
  padding-left: 1rem;
}

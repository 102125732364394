.userblocked-page__container {
  margin: 0 auto;
  max-width: 38rem;
  padding: 2rem 1rem;
}

@media (min-width: 28rem) and (min-height: 28rem) {
  .userblocked-page__container {
    margin: 4.875rem auto 0;
    padding: 3rem 2rem;
  }
}

.userblocked-page__icon {
  max-height: 5rem;
}

.userblocked-page__content {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
}

.userblocked-page__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.userblocked-page__title {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin: 0;
}

.requirements-check-group-row__icon {
  margin-right: 0.875rem;
  vertical-align: middle;
}

.requirements-check-group-row__icon--pass {
  color: var(--color-uva-green);
}

.requirements-check-group-row__icon--fail {
  color: var(--color-uva-red);
}

.requirements-check-group-row__icon--inconclusive {
  color: var(--color-uva-orange);
}

.requirements-check-group-row {
  border: 1px solid var(--color-pattens-blue);
}

.requirements-check-group-row:first-of-type {
  margin-top: 0.5rem;
}

.requirements-check-group-row:last-of-type {
  margin-bottom: 0.5rem;
}

.requirements-check-group-row__details-list {
  color: var(--color-scarpa-flow);
  list-style: none;
  margin: 0;
  padding: 0;
}

.requirements-check-group-row__details-list li {
  align-items: center;
  display: flex;
  padding: 0.5rem 1rem;
}

.requirements-check-group-row__details-list li:not(:last-child) {
  border-bottom: 1px solid var(--color-pattens-blue);
}

.toast-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: fixed;
  right: 2rem;
  top: 5rem; /* under navigation bar */
  width: 21.25rem;
  z-index: 90; /* place toasts under Backdrop but above all other */
}

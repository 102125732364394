.copy-button {
  appearance: none;
  background: none;
  border: none;
  color: var(--color-scarpa-flow);
  display: inline-flex;
  font-size: inherit;
  height: 0.75em;
  margin: 0;
  padding: 0;
  transform: scale(1);
  transition: 150ms transform ease-in-out;
  visibility: hidden;
  width: 0.75em;
}

:hover > .copy-button {
  visibility: visible;
}

.copy-button:hover {
  color: var(--color-black);
}

.copy-button--success {
  transform: scale(0);
}

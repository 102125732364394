.courses-table__period {
  margin-top: 0.375rem;
}

.courses-table__no-courses-content {
  display: flex;
}

.courses-table__no-courses-icon {
  color: var(--color-cobalt);
}

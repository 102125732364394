@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.toast {
  background-color: var(--color-white);
  border-radius: 0.25rem;
  box-shadow: 0 0 0.5rem rgb(0 0 0 / 15%);
  display: flex;
  gap: 1rem;
  padding: 0.75rem 2.75rem 0.75rem 1rem; /* extra padding on left to ensure no overlap of text and close button */
  position: relative;
}

.toast-visible {
  animation: fadein 0.3s linear forwards;
}

.toast-icon {
  align-items: center;
  background-color: transparent;
  border-radius: 0.125rem;
  color: var(--color-white);
  display: inline-flex;
  flex-shrink: 0;
  height: 2.5rem;
  justify-content: center;
  width: 2.5rem;
}

.toast-icon--success {
  background-color: var(--color-medium-aquamarine);
}

.toast-icon--warning {
  background-color: var(--color-dandelion);
}

.toast-icon--information {
  background-color: var(--color-cobalt);
}

.toast-content {
  flex: 1;
}

.toast-message {
  margin: 0;
}

/* negative margin to align close-button visually in toast while keeping accessible button size */
.toast-close-button {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
}

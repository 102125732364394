.listbox-container {
  height: 2.75rem;
  position: relative;
}

.listbox-trigger {
  align-items: center;
  background-color: transparent;
  border: 1px solid;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  font: inherit;
  height: 100%;
  justify-content: space-between;
  line-height: inherit;
  padding: 0 0.75rem;
  width: 100%;
}

.listbox-trigger:hover {
  background-color: var(--color-lavender);
}

.listbox-trigger--noborder {
  border: none;
}

.listbox-trigger:focus-visible {
  background-color: var(--color-lavender);
  outline: 1px solid var(--color-cobalt);
}

.listbox-trigger-icon {
  margin-left: 0.5rem;
}

.listbox {
  background-color: var(--color-white);
  border-radius: 0.5rem;
  box-shadow: 0 0 4px 0 rgba(0 0 0 / 25%);
  margin-top: 0.25rem;
  min-width: 100%;
  padding: 4px;
  position: absolute;
  width: fit-content;
  z-index: 20;
}

.listbox--align-right {
  right: 0;
}

.listbox-option {
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 0.5rem 0.75rem;
  position: relative;
  user-select: none;
  white-space: nowrap;
}

/* keyword active is styling that cover both on mouse:hover and keyboard:focus */
.listbox-option--active {
  background-color: var(--color-lavender);
}

.listbox-option[aria-selected="true"] {
  font-weight: var(--semibold);
}

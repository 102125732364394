.heading,
.heading__empty {
  margin: 0;
  white-space: normal;
}

.heading__actions {
  display: flex;
  gap: 1.5rem;
}

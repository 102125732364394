.constrained-choice__summary-header {
  padding: 0;
}

.constrained-choice__summary-heading {
  font-size: var(--font-size-text-non-essential);
  line-height: var(--line-height-s);
  margin: 0;
}

.constrained-choice__summary-content {
  margin: 0.5rem 0;
  padding: 0 0 0.5rem;
}

.constrained-choice__courses-header {
  align-items: baseline;
  border-bottom: 1px solid var(--color-pattens-blue);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 0 1rem;
}

.constrained-choice__courses-heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

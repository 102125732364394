.course-offering-selector__label {
  font-weight: var(--semibold);
  margin-top: 0.5rem;
  padding: 0;
}

.course-offering-selector__description {
  margin: 0;
  margin-bottom: 1rem;
}

.course-offering-selector__cards {
  border: 1px solid black;
  border-radius: 0.25rem;
}

.course-offering-selector__card-content {
  display: flex;
  padding: 1rem;
  position: relative;
}

.course-offering-selector__card:not(:last-of-type) {
  border-bottom: 1px solid var(--color-pattens-blue);
}

.course-offering-selector__card-content:hover {
  cursor: pointer;
}

.course-offering-selector__card-input {
  left: 1.5rem;
  margin: 0;
  position: absolute;
  top: 2rem;
}

.course-offering-selector__card-details {
  display: flex;
  flex-direction: column;
  margin-left: 3rem;
  width: 100%;
}

.course-offering-selector__card-items {
  display: flex;
  justify-content: space-between;
}

.topic-set-drawer__courses-item-periods {
  display: inline-flex;
  justify-self: end;
}

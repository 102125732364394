.table-row {
  background-color: transparent;
}

.table-row--is-selected {
  background-color: var(--color-ghost-white);
}

.table-row--sub-row > td {
  padding-left: 3rem;
}

.table-row--sub-row:not(.table-row--sub-row-last) > td {
  border-bottom: 1px solid var(--color-lavender-transparent);
}

.programme-list__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.programme-list__list-title {
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin: 0 0 0.5rem;
}

.programme-list__list-item {
  border-top: 1px solid var(--color-pattens-blue);
  color: var(--color-scarpa-flow);
  padding: 0.5rem 0;
}

.programme-list__list--inline .programme-list__list-item {
  border: none;
  padding: 0;
}

.programme-list__plan {
  color: var(--color-black);
  display: block;
}

.programme-list__date {
  color: var(--color-scarpa-flow);
  display: block;
}

.programme-list__list--inline .programme-list__date {
  display: unset;
}

.programme-list__list--spaced {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

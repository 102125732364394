.info-requirement-node {
  display: flex;
  flex-direction: column;
}

.info-requirement-node__header {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.course-offering-group-swap__table-section {
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1.5rem;
}

.course-offering-group-swap__table-header {
  align-items: baseline;
  border-bottom: 1px solid var(--color-pattens-blue);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  padding: 0 0 1.5rem;
}

.course-offering-group-swap__table-heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.course-offering-group-swap__table-empty {
  margin-bottom: 0;
}

.requirements__no-applicable-requirements-header {
  display: flex;
  gap: 0.5rem;
}

.requirements__no-applicable-requirements-icon,
.requirements__no-applicable-requirements-highlight-text {
  color: var(--color-uva-red);
}

.requirements__no-applicable-requirements-programme-list {
  margin: 0;
  padding-left: 1.75rem;
}

.tabs {
  margin: 0.5rem 0 0;
  position: relative;
  z-index: 0;
}

.tabs__button {
  background: none;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: 1rem;
}

.tabs__button[aria-expanded="true"] {
  font-weight: 700;
}

.tabs__button:disabled {
  pointer-events: none;
}

.tabs__list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.tabs::after {
  border-bottom: 0.25rem solid var(--color-pattens-blue);
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}

.tabs__item {
  border-bottom: 0.25rem solid transparent;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.tabs__item--is-active {
  border-bottom-color: var(--color-black);
}

.tabs__item:not(.tabs__item--is-active:not(:has(button:disabled))):hover {
  border-bottom-color: var(--color-french-grey);
}

.tabs__item:only-child .tabs__button:disabled {
  color: var(--color-black);
}

.tabs__content {
  display: none;
}

.tabs__content[aria-hidden="false"] {
  display: block;
}

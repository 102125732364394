:root {
  font-family:
    "Source Sans Pro",
    BlinkMacSystemFont,
    -apple-system,
    system-ui,
    sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;

  --font-size-default: 1rem; /* 16px */
  --font-size-l: 2rem; /* 32px */
  --line-height-l: calc(40 / 32); /* 40px */
  --font-size-m: 1.5rem; /* 24px */
  --line-height-m: calc(30 / 24); /* 30px */
  --font-size-s: 1.25rem; /* 20px */
  --line-height-s: calc(24 / 20); /* 24px */
  --font-size-text-non-essential: 0.875rem;
  --semibold: 600;
}

body {
  font-size: var(--font-size-default);
  line-height: 1.5;
}

h1 {
  font-family: utopia-std, serif;
  font-size: var(--font-size-l);
  font-weight: var(--semibold);
  line-height: var(--line-height-l);
  margin: 1rem 0 2.5rem;
}

h2 {
  font-family: utopia-std, serif;
  font-size: var(--font-size-m);
  font-weight: var(--semibold);
  line-height: var(--line-height-m);
  margin: 2.5rem 0;
}

h3 {
  font-family: utopia-std, serif;
  font-size: var(--font-size-m);
  font-weight: var(--semibold);
  line-height: var(--line-height-m);
  margin: 2.5rem 0 0.5rem;
}

p {
  margin: 1.5rem 0;
}

.action-header {
  background-color: var(--color-white);
  display: flex;
  justify-content: space-between;
}

.action-header__menu {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.action-header__actions {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  padding: 0 1rem;
}

.action-header-logo {
  display: block;
  height: 4rem;
  width: 4rem;
}

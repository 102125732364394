.group-swap-modal__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.group-swap-modal__description {
  margin: 0;
}

.group-swap-modal__section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.group-swap-modal__section-header {
  font-family: inherit;
  font-size: inherit;
  line-height: var(--line-height-s);
  margin: 0.75rem 0 0;
}

.group-swap-modal__group-wrapper {
  border: 1px solid var(--color-pattens-blue);
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.group-details {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.group-details_span {
  color: var(--color-scarpa-flow);
  display: flex;
  gap: 0.5rem;
}

.group-details__available {
  color: var(--color-san-felix);
}

.group-details__closed {
  color: var(--color-uva-red);
}

.group-details_tooltip-icon {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  max-height: 1rem;
}

.sticky-status-bar {
  overflow: hidden;
  padding: 0 2rem;
  position: sticky;
  text-align: center;
}

.sticky-status-bar--sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 20;
}

.sticky-status-bar__placeholder {
  height: 3rem;
}

.sticky-status-bar__message {
  margin: 0.75rem 0;
}

.sticky-status-bar--info {
  background-color: var(--color-lavender);
}

.sticky-status-bar--warning {
  background-color: var(--color-dandelion);
}

.sticky-status-bar--error {
  background-color: var(--color-uva-red);
  color: var(--color-white);
}

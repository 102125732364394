.status-indicator {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.status-indicator--completed,
.status-indicator--active {
  color: var(--color-uva-green);
}

.status-indicator--fail {
  color: var(--color-uva-red);
}

.status-indicator--pending {
  color: var(--color-uva-orange);
}

.status-indicator--ended {
  color: var(--color-scarpa-flow);
}

.tab-bar {
  display: flex;
  flex-wrap: wrap;
  margin: 1.5rem 0;
  position: relative;
  z-index: 0;
}

.tab-bar::after {
  border-bottom: 0.25rem solid var(--color-pattens-blue);
  bottom: 0;
  content: " ";
  left: 0;
  position: absolute;
  right: 0;
  z-index: -1;
}

.tab-bar__divider {
  margin: auto;
  opacity: 0;
}

.tab-bar__item {
  border-bottom: 0.25rem solid transparent;
  padding: 0.5rem 1rem;
  position: relative;
  text-decoration: none;
  white-space: nowrap;
}

.tab-bar__item--is-active {
  border-bottom-color: var(--color-black);
}

.tab-bar__item:not(.tab-bar__item--is-active):hover {
  border-bottom-color: var(--color-french-grey);
}

.rule-nodes__title {
  font-size: var(--font-size-default);
  margin: 1rem 0 0.5rem;
}

.rule-nodes__nodes {
  list-style: disc;
  margin: 0;
  padding-left: 1.5rem;
}

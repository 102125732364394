.rules-in-requirement {
  padding-top: 1rem;
}

.rules-in-requirement:not(:last-child) {
  border-bottom: 1px solid var(--color-pattens-blue);
  padding-bottom: 1rem;
}

.rules-in-requirement-title {
  font-size: 1rem;
  margin: 0 0 0.5rem;
}

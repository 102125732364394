.required-courses-node {
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
}

.required-courses-node__scroll-container {
  max-height: calc(4.5 * 1.5rem); /* if applicable, show half a row so scrollability is obvious */
  overflow: auto;
}

.required-courses-node__header {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.required-courses-node__subheader {
  margin: 0;
}

.required-courses-node__list {
  border-spacing: 1rem 0;
  color: var(--color-scarpa-flow);
  list-style: none;
  margin: 0;
  padding: 0 1rem;
}

.required-courses-node__list-item {
  display: grid;
  gap: 1rem;
  grid-template-columns: 4fr 2fr 1fr 4fr;
}

.required-courses-node__list-item-align {
  font-family: monospace;
  font-size: 0.875rem;
  text-align: right;
}

.required-courses-node__list-container {
  border: 1px solid var(--color-pattens-blue);
  padding: 1rem 0;
}

.required-courses-node__list-scroll-container {
  max-height: calc(4.5 * 1.5rem); /* if applicable, show half a row so scrollability is obvious */
  overflow: auto;
}

.required-courses-form__course-info-list {
  border: 1px solid var(--color-black);
  list-style: none;
  margin: 0;
  max-height: calc(5 * ((2 * 1.5rem) + (0.5rem * 2)));
  overflow-y: auto;
  padding: 0;
}

.required-courses-form__course-info-list-item {
  align-items: center;
  border-top: 1px solid var(--color-pattens-blue);
  display: grid;
  gap: 1rem;
  grid-template-columns: 8fr 1fr 1fr;
  padding: 0.5rem 1rem;
}

.required-courses-form__course-info-list-item:first-child {
  border: 0;
}

.required-courses-form__input-trailing-button {
  display: grid;
  gap: 1rem;
  grid-template-columns: 8fr 2fr;
}

.required-courses-form__requirement-item {
  align-items: center;
  display: flex;
  gap: 1rem;
  line-height: 1.75rem;
}

.required-courses-form__requirement-item-input {
  line-height: 1.5;
  max-width: 2rem;
  text-align: center;
}

.required-courses-form__ec {
  color: var(--color-scarpa-flow);
}

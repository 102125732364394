.fetched-student-requirement-status__student-card {
  max-height: 45vh;
  overflow: auto;
}

.fetched-student-requirement-status__student-card-header {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

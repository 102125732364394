.action-list {
  background-color: var(--color-white);
  box-shadow:
    1px 2px 2px rgb(0 0 0/ 6%),
    0 4px 3px rgba(0 0 0/ 7%);
  display: none;
  position: absolute;
  right: 0;
  top: calc(100% + 0.5rem);
  z-index: 100;
}

.action-list--open {
  display: block;
}

.action-list-container {
  position: relative;
}

.action-list [role="menuitem"] > * {
  background: 0;
  border: 0;
  cursor: pointer;
  display: block;
  font: inherit;
  margin: 0;
  padding: 0.5rem 0.75rem;
  text-align: left;
  white-space: pre;
  width: 100%;
}

.action-list [role="menuitem"] > *:not(:last-of-type) {
  border-bottom: 1px solid var(--color-pattens-blue);
}

.action-list [role="menuitem"] > *:hover {
  background-color: var(--color-ghost-white);
}

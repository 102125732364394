.error-page__container {
  margin: 0 auto;
  max-width: 38rem;
  padding: 2rem 1rem;
}

@media (min-width: 28rem) and (min-height: 28rem) {
  .error-page__container {
    padding: 3rem 2rem;
  }
}

.error-page__icon {
  max-height: 5rem;
}

.error-page__content {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
}

.error-page__text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.error-page__title {
  font-size: var(--font-size-m);
  line-height: var(--line-height-m);
  margin: 0;
}

.error-page__description,
.error-page__error-title,
.error-page__error-message {
  color: var(--color-scarpa-flow);
  margin: 0;
}

.error-page__error-title {
  font-size: var(--font-size-default);
  line-height: var(--line-height-default);
}

.error-page__error-message {
  font-size: var(--font-size-text-non-essential);
  margin-top: -0.5rem;
}

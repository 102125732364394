.page__header {
  padding: 1rem 0;
}

.page__title {
  margin: 0;
}

.page__sub-title {
  display: inline-block;
  margin-top: 0.5rem;
}

.page__breadcrumbs {
  align-items: center;
  color: var(--color-scarpa-flow);
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1.5rem;
}

.page__breadcrumbs-item:hover {
  color: var(--color-black);
}

.page__title-container {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
}

.page__actions {
  bottom: 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  left: 0;
  padding: 1rem;
  position: fixed;
  right: 0;
  z-index: 1;
}

@media (min-width: 48rem) {
  .page__actions {
    display: flex;
    padding: 0;
    position: static;
  }
}

.page__note {
  color: var(--color-scarpa-flow);
}

.page__content {
  padding: 0 1rem 1rem;
}

@media (min-width: 48rem) {
  .page__header {
    padding: 1.5rem 0;
  }

  .page__content {
    padding: 0 1.5rem 1.5rem;
  }
}

.topics-set__heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.topics-set__titles {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.topics-set__title--heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.topics-set__title {
  margin: 0;
  margin-bottom: 0.5rem;
}

.topics-set__topics-header {
  align-items: baseline;
  border-bottom: 1px solid var(--color-pattens-blue);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 0 1rem;
}

.topics-set__topics-heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.topics-set__notification {
  display: flex;
  gap: 0.25rem;
}

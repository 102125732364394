*::before,
*,
*::after {
  box-sizing: border-box;
}

body {
  background-color: var(--color-lavender);
  color: var(--color-black);
  margin: 0;
  min-height: 100vh;
}

img {
  margin: 0;
}

a {
  color: inherit;
}

svg {
  flex-shrink: 0;
}

.select {
  appearance: none;
  background-color: white;
  border: 1px solid;
  color: var(--color-black);
  font: inherit;
  font-weight: normal;
  margin: 0;
  width: 100%;
}

.select-container {
  position: relative;
  z-index: 0;
}

.select-container-icon {
  pointer-events: none;
  position: absolute;
  right: 1rem;
  z-index: 1;
}

.select--small {
  background-position: calc(100% - 0.5rem) center;
  border-color: var(--color-lemon-grass);
  height: 2rem;
  padding-left: 0.5rem;
  padding-right: 2rem;
}

.select--small + .select-container-icon {
  top: 0.5rem;
}

.select--regular {
  border-color: var(--color-black);
  border-radius: 0.5rem;
  height: 2.75rem;
  padding-left: 1rem;
  padding-right: 3rem;
}

.select--regular + .select-container-icon {
  top: 0.875rem;
}

.select:disabled {
  border-color: hsl(0deg 0% 0% / 10%);
  color: hsl(0deg 0% 0% / 10%);
}

.select:disabled + .select-container-icon {
  color: var(--color-black-transparent);
}

.select--full-width {
  width: 100%;
}

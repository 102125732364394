.rule-drawer__content {
  margin-right: 1rem;
}

.rule-drawer__requirements {
  margin: 0;
  padding: 0;
}

.rule-drawer__requirements-department {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.rule-drawer__requirements-department-name {
  color: var(--color-scarpa-flow);
}

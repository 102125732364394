.entry-requirement-drawer__content {
  margin-right: 1rem;
}

.entry-requirement-drawer__rules {
  margin: 0;
  padding: 0 0 1rem;
}

.entry-requirement-drawer__rules-rule {
  border-bottom: 1px solid var(--color-pattens-blue);
  padding: 1rem 0;
}

.entry-requirement-drawer__rule-title {
  font-size: 1rem;
  margin: 0;
}

.entry-requirement-drawer__rule-nodes {
  list-style: disc;
  margin: 0;
  padding-left: 1.5rem;
}

.entry-requirement-drawer__courses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
}

.entry-requirement-drawer__courses-item {
  align-items: first baseline;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 6fr 2fr;
}

.entry-requirement-drawer__courses-item-periods {
  display: inline-flex;
  justify-self: end;
}

.entry-requirement-drawer__topics {
  margin: 0;
  padding: 0;
}

.entry-requirement-drawer__topic-title {
  font-weight: bold;
}

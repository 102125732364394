.toggle-switch {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  gap: 1ch;
  user-select: none;
}

.toggle-switch__visible {
  align-items: center;
  background-color: var(--color-white);
  border: 1px solid var(--color-black);
  border-radius: 0.75rem;
  box-shadow:
    0 1px 1px hsla(0deg 0% 0% / 6%),
    0 1px 2px hsl(0deg 0% 0% / 10%);
  display: flex;
  height: 1.5rem;
  outline: 0.125rem dotted transparent;
  outline-offset: 0.125rem;
  padding: 0.25rem;
  transition: background-color 150ms;
  width: 3.125rem;
}

:focus-visible + .toggle-switch__visible {
  outline-color: var(--color-cobalt);
}

:checked + .toggle-switch__visible {
  background-color: var(--color-uva-green);
}

.toggle-switch__visible::before {
  background-color: var(--color-lavender);
  border: 1px solid var(--color-black);
  border-radius: 0.75rem;
  content: "";
  display: inline-block;
  height: 1rem;
  min-width: 1rem;
  transform: translateX(-0.1rem);
  transition:
    background-color 150ms,
    transform 150ms,
    width 150ms;
}

:checked + .toggle-switch__visible::before {
  background-color: var(--color-white);
  transform: translateX(1.6rem);
}

.toggle-switch--disabled .toggle-switch__visible::before {
  background-color: hsl(0deg 0% 0% / 10%);
  border-color: hsl(0deg 0% 0% / 10%);
}

:checked:active + .toggle-switch__visible::before {
  transform: translateX(1.25rem);
}

.toggle-switch--disabled,
.toggle-switch--loading {
  pointer-events: none;
}

.toggle-switch--disabled .toggle-switch__visible {
  background-color: var(--color-lavender);
  border-color: hsl(0deg 0% 0% / 10%);
  box-shadow: none;
}

.toggle-switch--disabled-label {
  color: var(--color-scarpa-flow);
}

.toggle-switch--loading-cursor {
  cursor: wait;
}

.toggle-switch--extra-left-gap {
  margin-left: 0.5rem;
}

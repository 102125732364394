.topic-set-drawer__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 1rem;
}

.topic-set-drawer__topic-set-titles {
  color: var(--color-scarpa-flow);
  margin: 0;
}

.topic-set-drawer__topics {
  margin: 0;
  padding: 0;
}

.topic-set-drawer__topics-title {
  font-size: var(--font-size-default);
  margin: 0;
}

.topic-set-drawer__courses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  margin: 1rem 0 0;
  padding: 0;
}

.topic-set-drawer__courses-item {
  align-items: center;
  align-items: baseline;
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 6fr 2fr;
}

.topic-set-drawer__courses-item-periods {
  display: inline-flex;
  justify-self: end;
}

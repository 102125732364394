.requirements-check-group-row-header__icon {
  margin-right: 0.875rem;
  vertical-align: middle;
}

.requirements-check-group-row-header__icon--pass {
  color: var(--color-uva-green);
}

.requirements-check-group-row-header__icon--fail {
  color: var(--color-uva-red);
}

.requirements-check-group-row-header__icon--inconclusive {
  color: var(--color-uva-orange);
}

.requirements-check-group-row-header,
.requirements-check-group-row-sub-header {
  display: flex;
}

.requirements-check-group-row-header {
  font-weight: var(--semibold);
  margin: 0;
  padding: 0.75rem;
}

.td {
  border-bottom: 1px solid var(--color-lavender);
  padding: 0.6875rem 0.75rem;
  vertical-align: top;
}

.td--numeric {
  font-variant-numeric: tabular-nums;
  text-align: right;
  width: 1rem; /* Hack to set the width to fit the content */
}

.td--button {
  padding: 0 0.75rem;
  vertical-align: middle;
  width: 1rem; /* Hack to set the width to fit the content */
}

.td--no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.td--max-width-small {
  max-width: 4rem;
}

.td--max-width-medium {
  max-width: 12rem;
}

.td--max-width-large {
  max-width: 24rem;
}

.td--min-width-small {
  min-width: 4rem;
}

.td--min-width-medium {
  min-width: 12rem;
}

.td--min-width-large {
  min-width: 24rem;
}

.td--fixed-width-small {
  width: 4rem;
}

.td--fixed-width-medium {
  width: 12rem;
}

.td--fixed-width-large {
  width: 24rem;
}

.td__flex {
  align-items: center;
  display: flex;
}

.td--button .td__flex {
  gap: 0.5rem;
}

.update-constrained-choice {
  align-items: center;
  display: flex;
  gap: 1ch;
}

.update-constrained-choice__title {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.update-constrained-choice__form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  max-width: 50rem;
}

.app-header {
  background-color: var(--color-white);
  box-shadow: inset 0 -1px 0 0 var(--color-lavender);
  display: flex;
  justify-content: space-between;
}

.app-header__sub-header {
  align-items: center;
  background-color: var(--color-ghost-white);
  display: flex;
  height: 3.5rem;
  justify-content: space-between;
}

.app-header__menu {
  align-items: center;
  display: flex;
  gap: 1rem;
}

.app-header__department-and-period-filter {
  margin-right: 1.5rem;
}

.app-header__actions {
  align-items: center;
  display: flex;
  gap: 0.25rem;
  padding: 0 1rem;
}

.app-header-logo {
  display: block;
  height: 4rem;
  width: 4rem;
}

.app-header__navigation {
  align-items: center;
  display: flex;
  gap: 1.5rem;
}

.app-header__sub-navigation {
  align-items: center;
  display: flex;
  font-size: var(--font-size-text-non-essential);
  gap: 2rem;
  padding: 0 1rem;
}

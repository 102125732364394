.entry-requirement__header {
  align-items: baseline;
  border-bottom: 1px solid var(--color-pattens-blue);
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 0 0 2.5rem;
}

.entry-requirement__notification {
  display: flex;
  gap: 0.25rem;
}

.entry-requirement__editable-heading {
  align-items: center;
  display: flex;
  gap: 1ch;
}

.entry-requirement__heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.entry-requirement__headers-allocationRound {
  min-width: 7.5rem;
}

.entry-requirement__headers-include-in-retry {
  min-width: 6rem;
}

.include-in-retry {
  align-items: center;
  display: flex;
  gap: 0.25rem;
}

.rule-heading {
  font-size: var(--font-size-s);
  line-height: var(--line-height-s);
  margin: 0;
}

.rule-requirement__notification {
  display: flex;
  gap: 0.25rem;
}

.rule-requirement {
  display: flex;
  flex-direction: column;
}

.rule-requirement-header {
  align-items: center;
  display: flex;
  gap: 1.5rem;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.rule-requirement-header__subheader {
  margin: 0;
}

.group-requirement-list {
  gap: 1rem;
  list-style: none;
  margin: 0;
  padding: 0;
}

.group-requirement-list-item {
  border: 1px solid var(--color-pattens-blue);
  color: var(--color-scarpa-flow);
  padding: 1rem;
}

.group-requirement-list-item:not(:first-child) {
  margin-top: -1px;
}

.rule-optional-requirements {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

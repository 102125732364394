.department-option__wrapper {
  --child-indent: 1.25rem;
  --vertical-lift: 0.25rem;

  background-color: transparent;
}

.department-option__level2,
.department-option__level2--disabled,
.department-option__level3,
.department-option__level3--last {
  display: flex;
  height: 100%;
  position: relative;
  user-select: none;
  white-space: nowrap;
}

.department-option__level2--disabled {
  color: var(--color-french-grey);
  cursor: default;
}

.department-option__level3,
.department-option__level3--last {
  padding-left: var(--child-indent);
}

.department-option__level3::before,
.department-option__level3--last::before {
  border: 1px solid var(--color-fog);
  content: "";
  height: 1px;
  left: 0.25rem;
  position: absolute;
  top: 50%;
  width: 0.75rem;
  z-index: 10;
}

.department-option__level3::after,
.department-option__level2::after,
.department-option__level2--disabled::after {
  border: 1px solid var(--color-fog);
  content: "";
  height: calc(100% + 1rem);
  left: 0.25rem;
  position: absolute;
  top: 50%;
  width: 1px;
  z-index: 10;
}

.department-option__level2::after,
.department-option__level2--disabled::after {
  height: calc(100% + var(--vertical-lift));
  top: 100%;
}

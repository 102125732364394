.constrained-choice-drawer__content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 1rem;
}

.constrained-choice-drawer__constrained-choice-registration-limit {
  color: var(--color-scarpa-flow);
  margin: 0;
}

.constrained-choice-drawer__courses {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style: none;
  padding: 0;
}

.constrained-choice-drawer__courses li:not(:last-child) {
  border-bottom: 1px solid var(--color-pattens-blue);
  padding-bottom: 0.5rem;
}

.constrained-choice-drawer__courses-item {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.constrained-choice-drawer__courses-item-details {
  align-items: baseline;
  display: flex;
  justify-content: space-between;
}

.constrained-choice-drawer__courses-item-ec {
  display: inline-flex;
  justify-content: flex-end;
  min-width: 10rem;
}

.academic-year-and-department-selector-container {
  align-items: center;
  border-radius: 6.25rem;
  display: flex;
  padding: 1px;
  width: fit-content;
}

.academic-year-and-department-selector__department-selector {
  width: 9.75rem;
}

.academic-year-and-department-selector__year-selector {
  margin-right: -1px;
  width: 9rem;
}

.group-selector-header {
  font-family: inherit;
  font-size: inherit;
  line-height: var(--line-height-s);
  margin: 0.5rem 0 0;
}

.group-selector {
  border: 1px solid var(--color-scarpa-flow);
  border-radius: 0.25rem;
  max-height: 23.5rem;
  overflow: auto;
}

.group-selector__option:not(:last-of-type) {
  border-bottom: 1px solid var(--color-pattens-blue);
}

.group-selector__option-content {
  cursor: pointer;
  display: flex;
  gap: 1rem;
  padding: 1rem;
}

.group-selector__option-input {
  cursor: pointer;
}

.group-selector__option-content:has(.group-selector__option-input:disabled),
.group-selector__option-input:disabled {
  cursor: default;
}

.group-selector__group-details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input {
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--color-black);
  color: var(--color-black);
  font-family: inherit;
  font-size: inherit;
  height: 2rem;
  margin: 0;
  padding: 0 0.5rem;
}

.input--numeric {
  text-align: right;
  width: 8rem;
}

.input--error,
.input:invalid {
  border-color: var(--color-chilean-fire);
}

.input--full-with {
  min-width: 100%;
  width: 0; /* Reset the default width of the input */
}

.input::placeholder {
  color: var(--color-black-transparent);
  font-size: inherit;
  opacity: 1;
}

.input[type="file"] {
  border: none;
}

.input:disabled {
  color: var(--color-black-transparent);
}
